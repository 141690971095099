
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";

const base = namespace("base");
const announcement = namespace("announcement");
@Component({
  components: { GeneralVehicleSelect }
})
export default class AnnouncementEdit extends Vue {
  @base.Action getDictionaryList;
  @announcement.Action addAnnouncement;
  @announcement.Action getAnnouncementList;
  @announcement.Action editAnnouncement;

  dayjs = dayjs;

  get breadData() {
    return [
      { name: t("v210831.announcement-management"), path: "/management/announcement-list" },
      {
        name:
          this.$route.name === "ManagementAnnouncementAdd"
            ? t("v210831.new-announcement")
            : t("v210831.edit-announcement")
      }
    ];
  }
  get isSystemSet() {
    return this.$route.name === "ManagementAnnouncementAdd";
  }
  announcementFormData = {
    announcementRange: [],
    title: "",
    content: "",
    announcementTime: [],
    beginTime: "",
    endTime: "",
    status: 1,
    source: 0,
    orgCode: ""
  };
  brandList: any = [];
  storeTypeList: any[] = [];
  categoryCasProps: any = {
    label: "categoryName",
    value: "categoryCode"
  };
  pickerOptions = {
    disabledDate(time) {
      return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
    }
  };
  announcementFormRules = {
    title: [{ required: true, message: t("v210831.please-enter-the-notice-name"), trigger: "blur" }],
    announcementTime: [{ required: true, message: t("v210831.please-enter-the-notice-time"), trigger: "blur" }],
    announcementRange: [{ required: true, message: t("v210831.please-enter-the-notice-scope"), trigger: "blur" }],
    content: [
      { required: true, message: t("v210831.please-enter-the-notice-content"), trigger: "blur" },
      { min: 0, max: 50, message: t("v210831.the-length-cannot-50-chars"), trigger: "blur" }
    ]
  };
  get isAdd() {
    return this.$route.name === "ManagementAnnouncementAdd";
  }
  created() {
    this.init();
  }

  submitForm(formName) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        if (this.isAdd) {
          this.handleaddAnnouncement();
        } else {
          this.handleEditAnnouncement();
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  async init() {
    this.getDictionaryList("store_type").then(data => {
      // console.log(data, "storeTypeList");
      this.storeTypeList = data;
    });

    if (!this.isAdd) {
      const { data } = await this.getAnnouncementList({
        announcementCode: this.$route.params.id
      });
      this.announcementFormData = data.list[0];
      this.$set(this.announcementFormData, "announcementTime", [data.list[0].beginTime, data.list[0].endTime]);
      // console.log(this.announcementFormData.announcementTime, " this.announcementFormData.announcementTime");
    }
  }

  async handleaddAnnouncement() {
    this.announcementFormData.beginTime = this.dayjs(this.announcementFormData.announcementTime[0]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    this.announcementFormData.endTime = this.dayjs(this.announcementFormData.announcementTime[1]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    await this.addAnnouncement(this.announcementFormData).then(() => {
      this.$message.success(t("v210831.new-announcement-succeeded"));
      this.$router.push("/management/announcement-list");
    });
  }
  async handleEditAnnouncement() {
    this.announcementFormData.beginTime = this.dayjs(this.announcementFormData.announcementTime[0]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    this.announcementFormData.endTime = this.dayjs(this.announcementFormData.announcementTime[1]).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    await this.editAnnouncement(this.announcementFormData).then(() => {
      this.$message.success(t("v210831.edit-announcement-succeeded"));
      this.$router.push("/management/announcement-list");
    });
  }
}
